import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'shout',
    loadChildren: () => import('./shout/shout.module').then( m => m.ShoutPageModule)
  },
  {
    path: 'shout-tabs',
    loadChildren: () => import('./shout-tabs/shout-tabs.module').then( m => m.ShoutTabsPageModule)
  },
  {
    path: 'shout-overview',
    loadChildren: () => import('./shout-overview/shout-overview.module').then( m => m.ShoutOverviewPageModule)
  },
  {
    path: 'shout-invite',
    loadChildren: () => import('./shout-invite/shout-invite.module').then( m => m.ShoutInvitePageModule)
  },
  {
    path: 'shout-manage',
    loadChildren: () => import('./shout-manage/shout-manage.module').then( m => m.ShoutManagePageModule)
  },
  {
    path: 'marketplace',
    loadChildren: () => import('./marketplace/marketplace.module').then( m => m.MarketplacePageModule)
  },
  {
    path: 'tasks',
    loadChildren: () => import('./tasks/tasks.module').then( m => m.TasksPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'live-ticker',
    loadChildren: () => import('./live-ticker/live-ticker.module').then( m => m.LiveTickerPageModule)
  },
  {
    path: 'shout-steps',
    loadChildren: () => import('./shout-steps/shout-steps.module').then( m => m.ShoutStepsPageModule)
  },
  {
    path: 'shout-form',
    loadChildren: () => import('./shout-form/shout-form.module').then( m => m.ShoutFormPageModule)
  },
  {
    path: 'shout-other',
    loadChildren: () => import('./shout-other/shout-other.module').then( m => m.ShoutOtherPageModule)
  },
  {
    path: 'shout-featured',
    loadChildren: () => import('./shout-featured/shout-featured.module').then( m => m.ShoutFeaturedPageModule)
  },
  {
    path: 'shout-reports',
    loadChildren: () => import('./shout-reports/shout-reports.module').then( m => m.ShoutReportsPageModule)
  },
  {
    path: 'shout-chat',
    loadChildren: () => import('./shout-chat/shout-chat.module').then( m => m.ShoutChatPageModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
