import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChannelService } from 'src/app/services/channel/channel.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UnitaskrAuthService } from 'src/app/services/unitaskr-auth/unitaskr-auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {

  /** PubNub message item */
  @Input('messageItem') messageItem;

  /** Focussed conversation */
  @Input('conversation') conversation;

  /** Which PubNub channel are we running on? */
  @Input('channel') channel_id: string;

  /** Output message action */
  @Output('messageAction') messageAction = new EventEmitter();


  /** Who am I? */
  // whoAmI: string = `${this.storageService.uniTaskrUser.userId}`;
  whoAmI: string = this.uniAuthService.uniTaskrUser ? this.uniAuthService.uniTaskrUser.id : "Testing";

  /** Has a price change request come in? And what is it? */
  showIncomingPriceChangeRequest;
  /** I sent a price change request - tell me */
  myPriceChangeRequest;
  /** Price change has been responded to */
  priceChangeMilestone;

  /** Have we already responded to this price change request? */
  showPriceChangeButtons: boolean = true;


  /** Change price object */
  focussedChangePrice;


  /** File downloaded from picture message */
  pictureMessageFile: any;

  stopTrying: boolean = false;

  constructor(
    private storageService: StorageService,
    // private priceChangeService: PriceChangeService,
    public channelService: ChannelService,
    public uniAuthService: UnitaskrAuthService
  ) { }

  async ngOnInit() {

    console.log('conversation conversation conversation conversation: ', this.conversation);
    console.log('messageItem messageItem messageItem messageItem: ', this.messageItem);
    if (
      (this.messageItem.message && this.messageItem.message.content && this.messageItem.message.content.type == "Price Update Request") ||
      (this.messageItem.message && this.messageItem.message.content && this.messageItem.message.content.type == "Price Change Request")) {
      // We need to get the details of the request and show different stuff... 
      console.log('Request GUUID: ', this.messageItem.message.content.priceChangeId);

      // this.priceChangeService.getPriceChangeById(this.messageItem.message.content.priceChangeId).subscribe(priceChange => {
      //   console.log('priceChange: ', priceChange);

      //   this.focussedChangePrice = priceChange;

      //   // If I sent the price change request, leave the milestone marker.

      //   // If I receive the request, show me a box!
      //   // Request is untouched
      //   if (!priceChange.isAccepted && !priceChange.isDeclined && (priceChange.userId !== this.storageService.uniTaskrUser.userId)) {
      //     console.log('Show me a the accept/decline window');
      //     this.showIncomingPriceChangeRequest = priceChange;
      //   } else if (priceChange.userId == this.storageService.uniTaskrUser.userId) {
      //     console.log('I sent the request');
      //     this.myPriceChangeRequest = priceChange;
      //   }

      //   // If request is accepted
      //   if (priceChange.isAccepted) {
      //     console.log('priceChange is accepted!');
      //     this.priceChangeMilestone = priceChange;
      //   }


      // }, err => {
      //   console.log('priceChange error: ', err);
      // })
    }
  }

  /**
   * What type of thing are we showing?
   * Message sent by me
   * Message sent by not-me
   * Milestone marker (Changed price, hired, etc..)
   */
  getTransactionType() {
    if (this.messageItem.message && this.messageItem.message.type && this.messageItem.message.type == "text") {
      return "text_message"
    } else if (this.messageItem.message && this.messageItem.message.type && this.messageItem.message.type == "milestone") {
      return "milestone"
    } else if (this.messageItem.message && this.messageItem.message.type && this.messageItem.message.type == "price_change") {
      return "price_change"
    } else if (this.messageItem.message && this.messageItem.message.type && this.messageItem.message.type == "price_change_milestone") {
      return "milestone_price"
    }

    else if (this.messageItem.message && this.messageItem.message.type && this.messageItem.message.type == "image") {
      return "image_row"
    }


  }

  getMilestoneType() {

    switch (this.messageItem.message.content.type) {
      case 'Hired':
        return "Hired";
      case 'Student Completed Task':
        return "student_complete";
      case 'Price Update Request':
        return "update_request";
      case 'Price Change Request':
        return 'price_change'
      case 'Price Change Accepted':
        return 'change_accepted'
      case 'Price Change Declined':
        return 'change_declined'
      case 'PAID':
        return 'paid'
    }

    if (this.messageItem.message.content.type == "Hired") {
      return "Hired"
    } else if (this.messageItem.message.content.type == "Price Update Request") {
      return "update_request"
    }
  }


  /**
   * Did I send this message?
   */
  getIsMyMessage() {
    if (this.messageItem.uuid == this.whoAmI || this.messageItem.message.sender == this.whoAmI) {
      return true;
    } else {
      return false;
    }
  }

  getMessageText() {
    // console.log('this.messageItem.message: ', this.messageItem.message);
    if (this.messageItem.message && this.messageItem.message.content) {
      return this.messageItem.message.content;
    } else {
      return "Empty message..."
    }
  }

  getSentTime() {
    if (this.messageItem.message && this.messageItem.message.timeSent) {
      return new Date(this.messageItem.message.timeSent)
    }
  }

  getSenderName(userId) {
    for (let user of this.conversation.users) {
      if (user.userId == userId) {
        return `${user.firstName}`
      }
    }
  }

  respondToPriceRequest(selection) {
    this.showPriceChangeButtons = false;
    switch (selection) {
      case -1:
        this.messageAction.emit({
          type: "price_change",
          value: "decline",
          messageTimeToken: this.messageItem.timetoken,
          priceChangeId: this.messageItem.message.content.priceChangeId
        });
        break;
      case 1:
        this.messageAction.emit({
          type: "price_change",
          value: "accept",
          messageTimeToken: this.messageItem.timetoken,
          priceChangeId: this.messageItem.message.content.priceChangeId
        });
        break;
    }
  }

  getImageSource(image) {
    // console.log('getImageSource(): ', image);
    if (image && typeof (image) == "string" && image.includes('http')) {
      return image;
    } else if (image == "string" || image == "") {
      return "../../../assets/images/placeholder.jpeg";
    } else {
      let availWidth = screen.availWidth;

      let imageWidth: number;
      let imageHeight: number;

      imageWidth = 2 * 0.95 * availWidth;
      imageHeight = 2 * 240;


      let cdnRequestObject = {
        bucket: "unitaskr-web-cdn",
        key: image.key ? image.key : image, // "Screenshot 2021-11-17 at 11.10.24.png",
        edits: {
          resize: {
            width: imageWidth,
            height: imageHeight,
            fit: "cover"
          }
        }
      }

      let base64 = btoa(JSON.stringify(cdnRequestObject))
      return `${environment.uniTaskrApi.cdnBaseUrl}${base64}`;
    }
  }

}
