import { Component } from '@angular/core';
import { FirebaseService } from './services/firebase/firebase.service';
import { UnitaskrAuthService } from './services/unitaskr-auth/unitaskr-auth.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Dashboard', url: '/dashboard', icon: 'speedometer' },
    { title: 'SHOUT', url: '/shout', icon: 'chatbox' },
    { title: 'Tasks', url: '/tasks', icon: 'briefcase' },
    { title: 'Marketplace', url: '/marketplace', icon: 'storefront' },
  ];

  public secondaryPages = [
    { title: 'Notifications', url: '/notifications', icon: 'notifications' },
    { title: 'Live Ticker', url: '/live-ticker', icon: 'people' },
  ]

  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private firebaseService: FirebaseService,
    public uniAuthService: UnitaskrAuthService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.firebaseService.startListeningToAuthStateChange();
  }
}
