import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpService } from '../http/http.service';


@Injectable({
  providedIn: 'root',
})
export class UnitaskrAuthService {

  public uniTaskrUser: any;
  public myProfile: any;

  public permissionList: any;

  constructor(
    private http: HttpService
  ) { }

  login(email: string) {
    return this.http.runHttpCall(
      "POST",
      "api/auth/login",
      "application/json",
      {
        email: email
      })
  }

  getMyProfile() {
    return this.http.runHttpCall(
      "GET",
      "api/profile",
      "application/json")
  }

  getMyAccountInfo() {
    return this.http.runHttpCall(
      "GET",
      "api/account",
      "application/json")
  }

  /** Register Business */
  registerBusiness(
    email: string, 
    firstName: string,
    lastName: string, 
    password: string,
    businessName: string,
    businessDesc: string,
    website: string,
    instagram: string,
    profilePic: string
  ) {
    return this.http.runHttpCall(
      "POST",
      "api/auth/register/business",
      "application/json",
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        businessName: businessName,
        referralCode: "",
        mobile: "",
        googleAccessKey: "",
        facebookAccessKey: "",
        appleAccessKey: "",
        countryCode: "GB",
        socialProfilePictureUrl: profilePic,
        profilePictureBase64: "",
        verificationCode: "",
        deviceToken: "",
        deviceType: true,
        securityEmailtoken: "",
        instagramName: instagram,
        website: website,
        whoAreYou: businessDesc,
        dateOfBirth: "",
        hometown: "",
        homeTownLocation: "",
        homeTownLat: 0,
        homeTownlon: 0,
        mainLocation: "",
        mainLocationName: "",
        countryName: "",
        mainLocationLat: 0,
        mainLocationLon: 0
      }
      ).pipe(tap(res => {
        console.log('Bus Bus Bus Bus Bus');
        console.log(res);
        this.uniTaskrUser = res;
        console.log('Bus Bus Bus Bus Bus');
      }));
    }
}