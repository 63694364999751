import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  constructor(
    private http: HttpService
  ) { }

  getLinkedConversation(linkedEntityId: number, linkedEntityType: number) {
    return this.http.runHttpCall(
      "POST",
      "api/channels/linked-entity",
      "application/json",
      {
        linkedEntityId: linkedEntityId,
        linkedEntityType: linkedEntityType // TaskOffer = 1, Service = 2, Shout = 3
      }
    )
  }

  createAdminChannel(name: string, users: number[], adminUsers: number[], linkedEntityId: number) {
    return this.http.runHttpCall(
      "POST",
      "api/channels/admin",
      "application/json",
      {
        name,
        users,
        adminUsers,
        linkedEntity: {
          linkedEntityId,
          linkedEntityType: 3 // 3 for Shout.
        }
      }
    )
  }
}
