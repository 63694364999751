import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpService
  ) { }

  /** Admin Funk Login Flow method */

  legacyLogin(email: string, password: string) {
    return this.http.runHttpCall(
      "POST",
      `api/adminauth/legacy/login`,
      "application/json", {
      email,
      password
    })
  }

  linkLogins(email: string, password: string) {
    return this.http.runHttpCall(
      "POST",
      `api/adminauth/legacy/link-login`,
      "application/json", {
      email,
      password
    })
  }

  adminAuth(email: string, password: string) {
    return this.http.runHttpCall(
      "POST",
      `api/adminauth/login`,
      "application/json", {
      email,
      password
    })
  }

  /** Admin Funk Login Flow method END */

}