import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public token: string = '';
  public FirebaseToken: string = '';
  public firebaseUser;
  public uniTaskrUser;
  public userId: string = '';

  constructor() { }

  set(key, value): Promise<any> {
    if (key == 'accessToken') {
      this.token = value;
    }
    return Storage.set({ key: key, value: value });
  }

  get(key): Promise<any> {
    return Storage.get({ key: key });
  }

  getToken(): any {
    return Storage.get({ key: 'accessToken' });
  }

  setAndString(key, value): Promise<any> {
    return this.set(key, JSON.stringify(value));
  }

  async getAndParse(key): Promise<any> {
    let res = await Storage.get({ key: key });
    try {
      return JSON.parse(res.value)
    } catch {
      return res.value
    }
  }

  Remove(key): Promise<any> {
    return Storage.remove({ key: key });
  }

  empty(): Promise<any> {
    return Storage.clear();
  }
}
