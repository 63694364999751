import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AvatarComponent } from './avatar/avatar.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { ManageShoutListItemComponent } from './manage-shout-list-item/manage-shout-list-item.component';
import { ServiceListItemComponent } from './service-list-item/service-list-item.component';
import { TaskListItemComponent } from './task-list-item/task-list-item.component';

@NgModule({
    declarations: [
        AvatarComponent,
        ChatMessageComponent,
        ServiceListItemComponent,
        TaskListItemComponent,
        ManageShoutListItemComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        IonicModule,
        
    ],
    exports: [
        AvatarComponent,
        ChatMessageComponent,
        ServiceListItemComponent,
        TaskListItemComponent,
        ManageShoutListItemComponent
    ]
})
export class ComponentsModule { }