import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from "dompurify";

@Injectable({
  providedIn: 'root'
})
export class SanitizerService {

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  getSanitized(htmlString: string) {
    let output = htmlString.replace("\n", "<br> <br>");
    const sanitizedContent = DOMPurify.sanitize(output);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }
}
