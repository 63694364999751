import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { Auth, createUserWithEmailAndPassword, getAuth, indexedDBLocalPersistence, initializeAuth, sendEmailVerification, signInWithCustomToken, signInWithEmailAndPassword } from 'firebase/auth';
import { Database, getDatabase } from 'firebase/database';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../http/http.service';
import { StorageService } from '../storage/storage.service';
import { UnitaskrAuthService } from '../unitaskr-auth/unitaskr-auth.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private readonly database: Database;
  private readonly auth: Auth;

  /** My global profile */
  public myProfile;
  /** My global Shout profile */
  public myShoutProfile;

  /** Where should we navigate after login? */
  targetPath: string;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private authService: AuthService,
    private uniAuthService: UnitaskrAuthService,
    private httpService: HttpService
  ) {
    this.targetPath = JSON.parse(JSON.stringify(window.location.pathname));
    console.log('£££££££££££££££££££££££');
    console.log('Init Firebase!');
    const firebaseApp = initializeApp(environment.firebaseConfig);
    console.log('£££££££££££££££££££££££');

    console.log('PATH PATH PATH PATH PATH');
    console.log('Path: ', this.targetPath);
    console.log('PATH PATH PATH PATH PATH');

    if (Capacitor.isNativePlatform()) {
      initializeAuth(firebaseApp, {
        persistence: indexedDBLocalPersistence
      });
    }

    this.database = getDatabase(firebaseApp);
    this.auth = getAuth(firebaseApp);
  }

  startListeningToAuthStateChange() {
    const auth = getAuth();
    auth.onAuthStateChanged(async (user: any) => {
      console.log('^^^^^^')
      console.log('Auth State Changed: ', user);
      console.log('^^^^^^');

      this.httpService.token = user.accessToken;


      this.storageService.getAndParse('login-details').then(loginCreds => {
        console.log('loginCreds: ', loginCreds);

        if (loginCreds) {
          this.authService.adminAuth(loginCreds.email, loginCreds.password).subscribe(adminAuthRes => {
            console.log('adminAuthRes: ', adminAuthRes);
            this.uniAuthService.uniTaskrUser = adminAuthRes;
            if (this.targetPath == "/login" || this.targetPath == "/") { 
              this.router.navigate([`/dashboard`]);
            } else {
              this.router.navigate([`${this.targetPath}`]);
            }
          });
        }
      })

    });
  }

  loginUserWithEmail(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  createUserWithEmail(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  sendVerificationEmail() {``
    let user = this.auth.currentUser;
    return sendEmailVerification(user);
  }

  async checkUserExists(email: string, password: string) {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password);
  }
}
