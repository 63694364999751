// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDPsRyovUUQhr6HBp1670EqydLZaGBferc",
    authDomain: "shout-admin-dev.firebaseapp.com",
    projectId: "shout-admin-dev",
    storageBucket: "shout-admin-dev.appspot.com",
    messagingSenderId: "314491081808",
    appId: "1:314491081808:web:cbb879e51f7792a020ad55",
    measurementId: "G-VRB57Y7PK4"
  },
  uniTaskrUtilities: {
    webBaseUrl: "http://localhost:8100/",
    iosBaseUrl: "capacitor://localhost"
  },
  uniTaskrApi: {
    // url: "https://app.unitaskr.com/"
    // url: "https://appfinal.unidoshdev.com/"
    // url: "http://localhost:3000/json_placeholder/",
    url: "https://api-dev.unitaskr.com/",

    cdnBaseUrl: "https://d3v5ng09h7jl33.cloudfront.net/",
    s3FileBaseUrl: "https://unitaskr-web-cdn.s3.eu-west-1.amazonaws.com/"
  },
  api: 'https://j3x0ln9gj7.execute-api.ap-northeast-1.amazonaws.com/dev/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
