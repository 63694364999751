import { Component, Input, OnInit } from '@angular/core';
import { SanitizerService } from 'src/app/services/sanitizer.service';

@Component({
  selector: 'app-task-list-item',
  templateUrl: './task-list-item.component.html',
  styleUrls: ['./task-list-item.component.scss'],
})
export class TaskListItemComponent implements OnInit {

  /** The particular record we want to show on this line */
  @Input('taskRecord') taskRecord: any;

  /** Is this the header row? */
  @Input('isHeader') isHeader: boolean | string = false;

  /** SHoudl we show the whole big details box? */
  showDetails: boolean = false;

  constructor(
    public sanitizer: SanitizerService
  ) { }

  ngOnInit() {
    console.log('taskRecord: ', this.taskRecord);
  }

  setShout() {
    
  }
  setPin() {

  }
  setPause() {

  }

  expandDetails() {
    this.showDetails = !this.showDetails;
  }

  isSet(flag: string) {
    if (this.taskRecord[flag]) {
      return true;
    } 
  }

}
