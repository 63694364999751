import { Component, Input, OnInit } from '@angular/core';
import { MarketplaceService } from 'src/app/marketplace/service/marketplace.service';
import { SanitizerService } from 'src/app/services/sanitizer.service';

@Component({
  selector: 'app-service-list-item',
  templateUrl: './service-list-item.component.html',
  styleUrls: ['./service-list-item.component.scss'],
})
export class ServiceListItemComponent implements OnInit {

  /** The particular record we want to show on this line */
  @Input('serviceRecord') serviceRecord: any;

  /** Is this the header row? */
  @Input('isHeader') isHeader: boolean | string = false;

  constructor(
    private marketplaceService: MarketplaceService,
    public sanitizer: SanitizerService
  ) { }

  ngOnInit() {}

  declineService() {
    this.marketplaceService.declineService(this.serviceRecord.serviceId).subscribe(declineRes => {
      console.log('declineRes: ', declineRes);
    }, err => {
      console.log('declineRes error: ', err);
    });
  }

  acceptService() {
    this.marketplaceService.approveService(this.serviceRecord.serviceId).subscribe(acceptRes => {
      console.log('acceptRes: ', acceptRes);
    }, err => {
      console.log('acceptRes error: ', err);
    });
  }

}
