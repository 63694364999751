import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-shout-list-item',
  templateUrl: './manage-shout-list-item.component.html',
  styleUrls: ['./manage-shout-list-item.component.scss'],
})
export class ManageShoutListItemComponent implements OnInit {

  /** The particular record we want to show on this line */
  @Input('manageItem') manageItem: any;

  /** Is this the header row? */
  @Input('isHeader') isHeader: boolean | string = false;

  /** SHoudl we show the whole big details box? */
  showDetails: boolean = false;

  constructor() { }

  ngOnInit() {}

  expandDetails() {
    this.showDetails = !this.showDetails;
  }

}
