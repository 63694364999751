import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {

  constructor(
    private http: HttpService
  ) { }

  searchByKeywordV2(keyword: string, categoryIds: number[], subCategoryIds: number[]): Observable<any[]> {
    return this.http.runHttpCall(
      "POST",
      "api/services/search/v2",
      "application/json",
      {
        searchQuery: keyword,
        page: {
          take: 25,
          skip: 0
        },
        // location: {
        //   distance: 0,
        //   lat: 0,
        //   lon: 0,
        //   locationType: 0
        // },
        categoryIds: categoryIds,
        subCategoryIds: subCategoryIds
      })
  }
  
  searchAll(keyword: string, categoryIds: number[], subCategoryIds: number[]): Observable<any[]> {
    return this.http.runHttpCall(
      "POST",
      "api/services/search/all/v2",
      "application/json",
      {
        searchQuery: keyword,
        page: {
          take: 25,
          skip: 0
        },
        // location: {
        //   distance: 0,
        //   lat: 0,
        //   lon: 0,
        //   locationType: 0
        // },
        categoryIds: categoryIds,
        subCategoryIds: subCategoryIds
      })
  }

  approveService(serviceId) {
    return this.http.runHttpCall(
      "POST",
      `api/services/admin/${serviceId}/approve`,
      "application/json",
      )
  }
  
  declineService(serviceId) {
    return this.http.runHttpCall(
      "POST",
      `api/services/admin/${serviceId}/decline`,
      "application/json",
      )
  }
}
